
import { VerticalAlignBottomOutlined } from '@ant-design/icons-vue';
import { defineComponent, onMounted, reactive, ref, toRefs,Ref, onBeforeMount } from "vue";
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import type { Dayjs } from 'dayjs';
import 'dayjs/locale/zh-cn';
import { getList } from '@/api';
import { downLoadExport, getPickerTime ,setUrl} from '@/utils/common';
import { message } from 'ant-design-vue';
import { number } from 'echarts';
export default defineComponent({
    name:"transactionFlow",
    components:{
        breadcrumb,
        VerticalAlignBottomOutlined
    },
    setup(){
        const data = ref<object []>([
            {path:'#/',title:'首页'},
            {path:'/index/saleManage',title:'销售管理'},
            {path:'#/',title:'交易流水'},
        ])
        const time = ref<Dayjs[]>([])
        const query = reactive({
            vip_type:'',
            mobile:'',
            card_number:'',
            checked:false
        })
        const dataSource = ref<any[]>([]);
        const columns = ref<object []>([
            { title: '卡号', dataIndex: 'card_number', key: 'card_number',},
            { title: '使用会员', dataIndex: 'nickname', key: 'nickname',},
            { title: '头像', dataIndex: 'avatar', key: 'avatar', },
            { title: '会员类型', dataIndex: 'type', key: 'type', },
            { title: '支付类型', dataIndex: 'pay_type_name', key: 'pay_type_name', },
            { title: '所属机构', dataIndex: 'province', key: 'province', },
            { title: '有效时间', dataIndex: 'start_time', key: 'start_time'}
        ])
        const pagination = reactive({
            count:0,
            page:1,
            perpage:10
        })
        const timeData = reactive({
            b_time:'',
            e_time:'',
        })

        onBeforeMount(()=>{
            getTableData()            
        })
        // 分页切换
        const tableChange = (pag:any,):void =>{
            pagination.page = pag.current;
            pagination.perpage = pag.pageSize;
            querySearch()
        }
        // 表格数据
        const getTableData = (data?:any):void =>{
            
            // let url:string = setUrl('/agent-api/api/my_order_flow?',data)
            getList('get','/agent-api/api/my_order_flow',data).then((res:any)=>{
                if(res.code == 200){
                    pagination.count = res.data.count;
                    pagination.page = Number(res.data.page);
                    pagination.perpage = res.data.perpage;
                    console.log(pagination.page);
                    
                    // res.data.perpage
                    dataSource.value = res.data.models;
                    // query.vip_type = '';
                    // timeData.b_time = '';
                    // timeData.e_time = '';
                    // query.mobile = '';
                    // query.card_number = '';
                    // time.value = []
                }
            })
        }
        // 时间发生变化
        const pickerChange = (date:any):void=>{
            let data:any;
            data = getPickerTime(date,0)
            
            timeData.b_time = data.start;
            timeData.e_time = data.end;
        }
        // 查询
        const querySearch = (isPage?:string):void =>{
            if(!time.value){
                timeData.b_time = '';
                timeData.e_time = '';
            }
            console.log(timeData);
            if(!/^[\da-z]+$/i.test(query.mobile) && query.mobile != ''){
              message.error('只能输入数字或英文')
              return
            }
            if(!/^[\da-z]+$/i.test(query.card_number) && query.card_number != ''){
              message.error('只能输入数字或英文')
              return
            }
            if(isPage == 'page'){
                pagination.page = 1;
            }
            
            let data = {
                vip_type:query.vip_type,
                b_time:timeData.b_time,
                e_time:timeData.e_time,
                mobile:query.mobile,
                card_number:query.card_number,
                page:pagination.page,
                perpage:pagination.perpage
            }
            getTableData(data)
        }

        const downLoad = ():void =>{
            if(!time.value){
                timeData.b_time = '';
                timeData.e_time = '';
            }
            let data:any = {
                vip_type:query.vip_type,
                b_time:timeData.b_time,
                e_time:timeData.e_time,
                mobile:query.mobile,
                card_number:query.card_number,
                page:pagination.page
            }
            let info:any = localStorage.getItem('userInfo')
            info = JSON.parse(info)
            let url:string = `/agent-api/api/order_flows_export?user_id=${info.id}&vip_type=${data.vip_type==null?'':data.vip_type}&b_time=${data.b_time}&e_time=${data.e_time}&mobile=${data.mobile}&card_number=${data.card_number}&page=${data.page}`
            
            if(process.env.NODE_ENV === "development"){
                window.open('https://v2.diancang.site' + url)
            }else{
                if(location.origin == 'http://agent.huobite.club'){
                    window.open('https://v2.diancang.site' + url)
                }else{
                    window.open(location.origin +'/agentApi' + url)
                }
            }
        }

        return{
            data,
            locale,
            columns,
            dataSource,
            time,
            ...toRefs(pagination),
            ...toRefs(query),
            tableChange,
            pickerChange,
            querySearch,
            downLoad
        }
    }
})
