import { service } from "@/utils/request";
import { AxiosResponse } from "axios";
import { api } from "./baseUrl";
import { setUrl } from "@/utils/common";
// 公共的list列表接口
export const getList = (method:string | any,url:string,data?:object): Promise<AxiosResponse<object>> =>{
    let listUrl = url
    if(method == 'get'){
        listUrl = setUrl(url+'?',data)
    }
    return service({
        method:method,
        url:`${api}${listUrl}`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

